.logo-wrapper {
    position: absolute;
    width: 50px;
    top: 10px;
    left: 20px;

    img {
        width: 100%;

        @include respond-to(tablet) {
            filter: invert(100%);
        }
    }
}

.logo-footer-wrapper {
    position: absolute;
    width: 20px;
    bottom: 10px;
    right: 20px;

    span {
        @include font-size(1.2rem);
        color: var(--sr-white);

        @include respond-to(tablet) {
            color: var(--sr-black);
        }
    }
}