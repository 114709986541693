.left-right-content-component {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    text-align: center;

    @include respond-to(tablet) {
        flex-direction: column-reverse;
        height: auto;
    }

    .left-content {
        width: calc(50% - 5px);
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to(tablet) {
            width: 100%;
        }

        .left-content-wrapper {
            width: 100%;
            max-width: 500px;
            padding: 20px;

            h2 {
                margin-bottom: 0px;
            }

            ul {
                li {
                    @include font-size(1.6rem);
                    text-align: left;
                    list-style-type: square;
                }
            }
        }

        .intro-content {
            @include respond-to(tablet) {
                display: none;
            }
        }

        .skill-content {
            display: inline-block;
            width: 100%;
            margin-top: 30px;

            @include respond-to(tablet) {
                margin-top: 0;
            }
        }
    }

    .right-content {
        width: calc(50% - 5px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--sr-black);
        color: var(--sr-white);

        @include respond-to(tablet) {
            width: 100%;
        }

        .right-content-wrapper {
            width: 100%;
            max-width: 500px;
            padding: 20px;

            @include respond-to(tablet) {
                padding-bottom: 40px;
            }

            .profile {
                @include respond-to(tablet) {
                    margin-top: 40px;
                }
            }

            .profile-image {
                width: auto;
                height: 250px;
                border-radius: 100%;
            }

            p {
                color: inherit;
            }

            a {
                color: inherit;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .resume-download {
                width: 100%;
                display: inline-block;
                margin-top: 20px;
            }
        }
    }
}