$breakpoints: (
    'mobile': 414px,
    'mobile-large': 600px,
    'tablet': 768px,
    'desktop': 992px,
    'ipad': 1024px,
    'large-desktop': 1200px,
    'extra-large-desktop': 1400px
) !default;

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}
